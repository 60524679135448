import React from 'react';
import ReactDOM from 'react-dom';
import { SocketsProvider } from './contexts/Sockets';
import App from './App';
import './index.css';

ReactDOM.render(
  <SocketsProvider>
    <App />
  </SocketsProvider>,
  document.getElementById('root')
);
