import React, { useEffect, useState, useRef } from 'react';
import { useSockets } from './contexts/Sockets';

import MessagePage from './pages/MessagePage';
import ConfigurationPage from './pages/ConfigurationPage';
import OverviewPage from './pages/OverviewPage';

import { Layout } from './types/index';

type ScreenStatus = 'loading' | 'disconnected' | 'configured' | 'unconfigured';

const App: React.FC = () => {
  const { screensSocket } = useSockets();
  const [screenStatus, setScreenStatus] = useState<ScreenStatus>('loading');
  const [screenName, setScreenName] = useState('');
  const [screenLayout, setScreenLayout] = useState<Layout>([]);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    screensSocket
      .on('connect', () => {
        if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
        setScreenStatus('loading');
        screensSocket.emit('screenKey', localStorage.getItem('screenKey'));
      })
      .on('screenKey', (key: string) => {
        localStorage.setItem('screenKey', key);

        // NOTE: "renderedLayout" only emits when a screen is already assigned a layout or gets unassigned a layout,
        // but not for new screens - hence updating screenStatus here as well. This causes a flash of
        // the ConfigurationPage on page load for configured screens. It works but not optimal.
        //
        // SOLUTION?: A boolean to determine a screens config status (e.g. "assignedLayout") emitted backend
        // on "screenKey" event could be a way of dealing with this. This would also allow us to listen for
        // "renderedLayout" further down the component tree (e.g. directly in Tiles.tsx).
        setScreenStatus('unconfigured');
      })
      .on('name', (name: string) => setScreenName(name))
      .on('renderedLayout', (layout: Layout | null) => {
        setScreenLayout(layout ? layout : []);
        setScreenStatus(layout ? 'configured' : 'unconfigured');
      })
      .on('disconnect', () => {
        if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          setScreenStatus('disconnected');
        }, 30000);
      })
      .on('reload', () => window.location.reload());

    return () => {
      if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
    };
  }, [screensSocket]);

  switch (screenStatus) {
    case 'loading': {
      return (
        <MessagePage
          heading="Hello?"
          message="Attempting to connect to server..."
          spinner
        />
      );
    }
    case 'disconnected': {
      return (
        <MessagePage
          heading="Houston?"
          message="We've lost server connection, attempting to reconnect..."
          spinner
        />
      );
    }
    case 'unconfigured': {
      return <ConfigurationPage screenName={screenName} />;
    }
    case 'configured': {
      return <OverviewPage screenLayout={screenLayout} />;
    }
  }
};

export default App;
