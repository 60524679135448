import React, { useEffect, useState } from 'react';
import { useSockets } from '../contexts/Sockets';
import evokoLogo from '../assets/evoko_logo.png';
import './Footer.css';

interface Props {
  showLeftLogo: boolean;
}

export const Footer: React.FC<Props> = ({ showLeftLogo }) => {
  const { chromeSocket } = useSockets();
  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => {
    chromeSocket
      .emit('logo')
      .on('logo', (logoFile: File | null) =>
        setLogo(logoFile ? URL.createObjectURL(new Blob([logoFile])) : null)
      );

    return () => {
      chromeSocket.off('logo');
    };
  }, [chromeSocket]);

  return (
    <footer className="Footer__content-wrapper">
      {(showLeftLogo && (
        <img
          alt="Company Logo"
          className="Footer__company-image"
          src={logo || evokoLogo}
        />
      )) || <div />}
      <img alt="Evoko Logo" className="Footer__evoko-image" src={evokoLogo} />
    </footer>
  );
};

export default Footer;
