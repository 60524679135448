import React, { useEffect, useState } from 'react';
import FlipTile from './FlipTile';
import './Tiles.css';

import { Layout } from '../types';

interface Props {
  showDirection: boolean;
  flipTime: number;
  renderedLayout: Layout;
  width: number;
  height: number;
}

const Tiles: React.FC<Props> = ({
  showDirection,
  flipTime,
  renderedLayout,
  width,
  height,
}) => {
  const [flipped, setFlipped] = useState(false);
  const [tilePadding, setTilePadding] = useState(0);
  const [tileSize, setTileSize] = useState(0);

  useEffect(() => {
    if (!showDirection) return setFlipped(false);

    const interval = setInterval(() => setFlipped(f => !f), flipTime * 1000);

    return () => clearInterval(interval);
  }, [flipTime, showDirection]);

  useEffect(() => {
    if (renderedLayout.length === 0) return;

    const [rows, columns] = [renderedLayout.length, renderedLayout[0].length];

    const spacePerTileForColumns = width / columns;
    const spacePerTileForRows = height / rows;

    const leastSpaceForTile = Math.min(
      spacePerTileForColumns,
      spacePerTileForRows
    );
    const padding = leastSpaceForTile * 0.04;

    // Margin left is one less than tiles since first doesn't get a margin
    const size =
      leastSpaceForTile === spacePerTileForColumns
        ? (width - (columns - 1) * padding) / columns
        : (height - (rows - 1) * padding) / rows;

    setTilePadding(padding);
    setTileSize(size);
  }, [width, height, renderedLayout]);

  return (
    <div className="Tiles__main-tile-area">
      <div className="Tiles__inner-tile-area">
        {renderedLayout.map((tilesRow, indexY) => {
          const lastRow = indexY === renderedLayout.length - 1;
          return (
            <div className="Tiles__tile-row" key={`row-${indexY}`}>
              {tilesRow.map((tile, indexX) => (
                <FlipTile
                  key={`row-${indexY}-tile-${indexX}`}
                  flipped={flipped}
                  tileSize={tileSize}
                  tilePadding={tilePadding}
                  tile={tile}
                  pos={{ x: indexX, y: indexY }}
                  lastRow={lastRow}
                  showDirection={showDirection}
                />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tiles;
