import React from 'react';
import './ConfigurationPage.css';

interface Props {
  screenName: string;
}

const ConfigurationPage: React.FC<Props> = ({ screenName }) => (
  <main className="ConfigurationPage__container">
    <h1 className="ConfigurationPage__heading">
      Hiya! This screen is ready to go!
    </h1>
    <p className="ConfigurationPage__message">
      screen name:
      <span className="ConfigurationPage__screenname">{screenName}</span>
    </p>
    <p className="ConfigurationPage__admin-link">
      Use the{' '}
      <a href="/admin" target="_blank">
        admin portal
      </a>{' '}
      to setup this screen!
    </p>
  </main>
);

export default ConfigurationPage;
