import React, { useEffect, useState } from 'react';
import { ArrowStraight } from '@evoko/overview-svgs';
import './FlipTile.css';

import { RoomViewData } from '../types/index';

const tileColor = {
  vacant: 'green',
  checkin: 'orange',
  inprogress: 'red',
};

// Default arrow is black
const arrowColor = {
  vacant: '',
  checkin: 'white',
  inprogress: 'white',
};

const arrowRotation = {
  'down-right': 45,
  'down-left': 135,
  left: 180,
  'up-left': 225,
  'up-right': 315,
  right: 0,
};

interface Props {
  tile: RoomViewData;
  tileSize: number;
  tilePadding: number;
  pos: { x: number; y: number };
  lastRow: boolean;
  showDirection: boolean;
  flipped: boolean;
}

const Tile: React.FC<Props> = ({
  tile,
  tileSize,
  tilePadding,
  pos,
  lastRow,
  showDirection,
  flipped,
}) => {
  const [tileFlipped, setTileFlipped] = useState(false);

  useEffect(() => {
    if (!showDirection) return setTileFlipped(false);

    const delay = 300 * Math.sqrt(pos.x * pos.x + pos.y * pos.y * 2);
    const timeout = setTimeout(() => setTileFlipped(flipped), delay);

    return () => clearTimeout(timeout);
  }, [showDirection, pos, flipped]);

  const tileSizeStyle = {
    height: tileSize,
    width: tileSize,
    marginLeft: pos.x !== 0 ? tilePadding : 0,
    marginBottom: lastRow ? 0 : tilePadding,
  };

  if (tile.blank) {
    return <div style={tileSizeStyle} />;
  }

  const bigFontSizeInPx = `${tileSize * 0.12}px`;
  const smallFontSizeInPx = `${tileSize * 0.08}px`;

  return (
    <div
      className={`FlipTile__tile FlipTile__tile--${tileColor[tile.status]}
        ${tileFlipped ? ' FlipTile__tile--flipped' : ''}
        ${showDirection ? ' FlipTile__tile--rotate' : ''}`}
      style={tileSizeStyle}
    >
      <div className="FlipTile__tile-content">
        <div className="FlipTile__tile-content-relative">
          <div className="FlipTile__tile-front">
            <div className="FlipTile__front-upper">
              <div
                className="FlipTile__tile-subject"
                style={{
                  fontSize: bigFontSizeInPx,
                }}
              >
                <div className="FlipTile__ellipsis FlipTile__ellipsis-two-rows">
                  {tile.subject !== '' ? tile.subject : tile.roomName}
                </div>
              </div>
              <div
                className="FlipTile__ellipsis FlipTile__ellipsis-two-rows"
                style={{ fontSize: smallFontSizeInPx }}
              >
                {tile.createdBy}
              </div>
            </div>
            <div className="FlipTile__front-lower">
              <div className="FlipTile__tile-timePrefix">
                <span style={{ fontSize: smallFontSizeInPx }}>
                  {tile.timeText}
                </span>
                <span
                  className="FlipTile__tile-timeDuration"
                  style={{ fontSize: bigFontSizeInPx }}
                >
                  {tile.timeDuration}
                </span>
              </div>
              <div
                className="FlipTile__tile-roomName"
                style={{ fontSize: smallFontSizeInPx }}
              >
                <div className="FlipTile__ellipsis FlipTile__ellipsis-one-row">
                  {tile.subject !== '' ? tile.roomName : ''}
                </div>
              </div>
            </div>
          </div>

          <div className="FlipTile__tile-back">
            <div
              className="FlipTile__tile-subject"
              style={{
                fontSize: bigFontSizeInPx,
              }}
            >
              <div className="FlipTile__ellipsis FlipTile__ellipsis-two-rows">
                {tile.roomName}
              </div>
            </div>
            <div className="FlipTile__tile-arrow-container">
              <div className="FlipTile__tile-direction">
                <ArrowStraight
                  style={{
                    transform: `rotate(${arrowRotation[tile.direction]}deg)`,
                  }}
                  className={
                    arrowColor[tile.status]
                      ? `FlipTile__tile-arrow--${arrowColor[tile.status]}`
                      : ''
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tile;
