import React, { useEffect, useState } from 'react';
import { useSockets } from '../contexts/Sockets';
import './LeftSidebar.css';

type Clock = { time: string; day: string; date: string };

interface Props {
  leastDimension: number;
  showDateTime: boolean;
  message: string;
}

const LeftSidebar: React.FC<Props> = ({
  leastDimension,
  showDateTime,
  message,
}) => {
  const { chromeSocket } = useSockets();
  const [clock, setclock] = useState<Clock>({ time: '', day: '', date: '' });

  useEffect(() => {
    chromeSocket
      .emit('clock')
      .on('clock', (clockData: Clock) => setclock(clockData));

    return () => {
      chromeSocket.off('clock');
    };
  }, [chromeSocket]);

  const bigFontSize = leastDimension * 0.06;
  const smallFontSize = bigFontSize * 0.75;

  const { time, day, date } = clock;

  return (
    <div className="LeftSidebar__full-height-container">
      <div
        style={{ fontSize: `${bigFontSize}px` }}
        className="LeftSidebar__message"
      >
        {message}
      </div>
      {showDateTime && (
        <div
          style={{ fontSize: `${smallFontSize}px` }}
          className="LeftSidebar__clock"
        >
          <div>{time}</div>
          <div>{day}</div>
          <div>{date}</div>
        </div>
      )}
    </div>
  );
};

export default LeftSidebar;
