import React from 'react';
import './MessagePage.css';

interface Props {
  heading: string;
  message?: string;
  spinner?: boolean;
}

const MessagePage: React.FC<Props> = ({ heading, message, spinner }) => (
  <main className="MessagePage__container">
    <h1>{heading}</h1>
    {message && <p>{message}</p>}
    {spinner && (
      <div className="MessagePage__spinner-container">
        <div className="MessagePage__spinner-node" />
        <div className="MessagePage__spinner-node" />
      </div>
    )}
  </main>
);

export default MessagePage;
