import React, { createContext, useContext, useEffect } from 'react';
import io from 'socket.io-client';

interface Sockets {
  screensSocket: SocketIOClient.Socket;
  chromeSocket: SocketIOClient.Socket;
}

const sockets: Sockets = {
  screensSocket: io.connect('/overview/screens', { transports: ['websocket'] }),
  chromeSocket: io.connect('/overview/chrome'),
};

const SocketsContext = createContext(sockets);

export const SocketsProvider: React.FC = ({ children }) => {
  useEffect(() => {
    return () => {
      sockets.chromeSocket.close();
      sockets.screensSocket.close();
    };
  }, []);

  return (
    <SocketsContext.Provider value={sockets}>
      {children}
    </SocketsContext.Provider>
  );
};

export const useSockets = (): Sockets => useContext(SocketsContext);
