import React, { useState, useEffect } from 'react';
import { useSockets } from '../contexts/Sockets';

import Tiles from '../components/Tiles';
import LeftSidebar from '../components/LeftSidebar';
import Footer from '../components/Footer';

import { Options, Settings, Layout } from '../types/index';

import './OverviewPage.css';

interface Props {
  screenLayout: Layout;
}

const OverviewPage: React.FC<Props> = ({ screenLayout }) => {
  const { chromeSocket, screensSocket } = useSockets();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [screenSettings, setScreenSettings] = useState<Settings>({
    showDateTime: false,
    showLeftLogo: false,
  });
  const [screenOptions, setScreenOptions] = useState<Options>({
    message: '',
    showDirection: false,
    flipTime: 0,
  });

  useEffect(() => {
    function updateWindowDimensions() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }

    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  useEffect(() => {
    chromeSocket
      .emit('settings')
      .on('settings', (settings: Settings) => setScreenSettings(settings));

    return () => {
      chromeSocket.off('settings');
    };
  }, [chromeSocket]);

  useEffect(() => {
    screensSocket
      .emit('options')
      .on('options', (options: Options) => setScreenOptions(options));

    return () => {
      screensSocket.off('options');
    };
  }, [screensSocket]);

  const leastDimension = Math.min(width, height);
  const margin = leastDimension * 0.02;

  function toBoolean(value: string | null): boolean {
    if (value !== typeof 'string') return false;
    return ['true', 'yes'].includes(value.toLowerCase()) ? true : false;
  }

  function toNumber(value: string | null, defaultValue: number): number {
    if (value !== typeof 'string') return defaultValue;
    const num = parseInt(value, 10);
    return Number.isNaN(num) ? defaultValue : num;
  }

  const url = new URL(window.location.href);
  const embedded = toBoolean(url.searchParams.get('embedded'));
  const embeddedMargin = toNumber(url.searchParams.get('margin'), margin);

  if (embedded) {
    return (
      <main style={{ margin: embeddedMargin }}>
        <Tiles
          height={height - embeddedMargin * 2}
          width={width - embeddedMargin * 2}
          renderedLayout={screenLayout}
          showDirection={screenOptions.showDirection}
          flipTime={screenOptions.flipTime}
        />
      </main>
    );
  }

  const upperHalfLeftWidth = leastDimension * 0.33;
  const upperHalfRightWidth = width - upperHalfLeftWidth - margin - margin;
  const footerHeight = leastDimension * 0.1;
  const upperHalfHeight = height - footerHeight - margin - margin;

  return (
    <div style={{ margin }}>
      <main
        className="OverviewPage__upper-half"
        style={{ height: upperHalfHeight }}
      >
        <div style={{ width: upperHalfLeftWidth }}>
          <LeftSidebar
            showDateTime={screenSettings.showDateTime}
            leastDimension={leastDimension}
            message={screenOptions.message}
          />
        </div>
        <div style={{ width: upperHalfRightWidth }}>
          <Tiles
            height={upperHalfHeight}
            width={upperHalfRightWidth}
            renderedLayout={screenLayout}
            showDirection={screenOptions.showDirection}
            flipTime={screenOptions.flipTime}
          />
        </div>
      </main>
      <div style={{ height: footerHeight }}>
        <Footer showLeftLogo={screenSettings?.showLeftLogo} />
      </div>
    </div>
  );
};

export default OverviewPage;
